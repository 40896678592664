import React from "react";
import { Review } from "../common";

import "./styles.scss";

const ReviewsContainer = () => {
  return (
    <div className="reviews-container">
      <div className="reviews-container__header">
        <img
          src="/img/pressroom/reviews/lock.png"
          alt="Lock."
          className="reviews-container__header__image"
        />
        <h2 className="reviews-container__header__title">Player reviews</h2>
      </div>
      <div className="reviews-container__wrapper">
        <Review
          name="Rein"
          content="Once I start I can’t stop I just want to sit there and constantly read books over and over again. It takes me away from reality and into my own little world!"
        />
        <Review
          name="Adrienne"
          content="I am grateful for the escape from reality. It expands my imagination and my own fantasies while i get to watch them unfold before my eyes. keeps me on my toes and i’m always excited to read more."
        />
        <Review
          name="Aimee"
          content="I enjoy how I’m able to connect to some of the stories. It helps me to express myself in a quiet way and to explore some of my own fantasies. I’m excited to finish the story I’m on now because I feel most connected to it."
        />
      </div>
    </div>
  );
};

export { ReviewsContainer };
