import React from "react";
import { ampEventWithEventProperty } from "../../../utils/amplitude";

import "./styles.scss";

const Main = () => {
  const openInNewTab = (link) => {
    window.open(link);
  };

  return (
    <div className="main">
      {/* <h2 className="main__title">A place to live out your fantasy</h2>
      <img
        src="/img/pressroom/main/posters.png"
        alt="Many stories posters."
        className="main__image"
      />
      <img
        src="/img/pressroom/main/posters-mobile.png"
        alt="Many stories posters."
        className="main__image-mobile"
      /> */}
      <div className="main__spicy">
        <div className="main__spicy__wrapper">
          <img
            src="/img/pressroom/main/stories.png"
            alt="Many interactive stories."
            className="main__spicy__wrapper__image"
          />
        </div>

        <div className="main__spicy__content">
          <h2 className="main__spicy__content__title">
            Spicy fantasies turned into interactive stories
          </h2>
          <span className="main__spicy__content__label">
            Most people are still ashamed of fantasizing about bold or
            provocative plots. Our mission is to change that.
            <br />
            <br />
          </span>
          <span
            className={
              "main__spicy__content__label main__spicy__content__label-mobile"
            }
          >
            &lsquo;My Fantasy&rsquo; interactive stories gives you a chance to
            throw off the shackles of embarrassment and meet with your deep
            fantasies. Choose a fantasy you like, flirt with whomever you like,
            explore your wildest desires and control the plot by yourself!
          </span>
          <img
            src="/img/pressroom/main/stories-mobile.png"
            alt="Many interactive stories."
            className="main__spicy__image-mobile"
          />
          <div className="main__spicy__content__download__mob">
              <img
                src="/img/pressroom/main/download-app-store-mob.png"
                alt="Download on the App Store."
                className="main__spicy__content__download__image__mob mr-22"
                onClick={() => {
                  ampEventWithEventProperty("button_click", {
                    parametr: "apple",
                  });
                  openInNewTab(
                    "https://apps.apple.com/ai/app/my-fantasy-choose-your-story/id1491717191"
                  );
                }}
              />
              <img
                src="/img/pressroom/main/download-google-play-mob.png"
                alt="Get it on Google Play."
                className="main__spicy__content__download__image__mob"
                onClick={() => {
                  ampEventWithEventProperty("button_click", {
                    parametr: "google play",
                  });
                  openInNewTab(
                    "https://play.google.com/store/apps/details?id=gmem.episode&hl=en_US&gl=US"
                  );
                }}
              />
          </div>
          <div className="main__spicy__content__download">
              <img
                src="/img/pressroom/main/download-app-store.png"
                alt="Download on the App Store."
                className="main__spicy__content__download__image mr-22"
                onClick={() => {
                  ampEventWithEventProperty("button_click", {
                    parametr: "apple",
                  });
                  openInNewTab(
                    "https://apps.apple.com/ai/app/my-fantasy-choose-your-story/id1491717191"
                  );
                }}
              />
              <img
                src="/img/pressroom/main/download-google-play.png"
                alt="Get it on Google Play."
                className="main__spicy__content__download__image"
                onClick={() => {
                  ampEventWithEventProperty("button_click", {
                    parametr: "google play",
                  });
                  openInNewTab(
                    "https://play.google.com/store/apps/details?id=gmem.episode&hl=en_US&gl=US"
                  );
                }}
              />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Main };
