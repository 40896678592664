import amplitude from "amplitude-js";

const ampInst = amplitude.getInstance();

ampInst.init(process.env.REACT_APP_AMPLITUDE_API_KEY);

function ampEvent(event) {
  ampInst.logEvent(event);
}

function ampEventWithEventProperty(event, props) {
  ampInst.logEvent(event, props);
}

function ampSetUserProperty(key, value) {
  const identify = new amplitude.Identify().set(key, value);
  ampInst.identify(identify);
}

export { ampEvent, ampEventWithEventProperty, ampSetUserProperty };
