import React from "react";
import {
  ampEvent,
  ampEventWithEventProperty,
} from "../../../../utils/amplitude";

import "./styles.scss";

const PressroomFooter = () => {
  const openInNewTab = (link) => {
    window.open(link);
  };

  return (
    <footer className="pressroom-footer">
      <div className="pressroom-footer__border upper"></div>
      <img
        src="/img/pressroom/logo.png"
        alt="My Fantasy logo."
        className="pressroom-footer__image"
      />
      <h2 className="pressroom-footer__title">Pressroom</h2>
      <span className="pressroom-footer__label">
        We&apos;re happy to invite you to discuss our app and stories. We want
        to share our thoughts on sexuality, from breaking taboos to exploring
        new frontiers!
      </span>
      <span
        className="pressroom-footer__contact"
        onClick={() =>
          openInNewTab(
            "https://mail.google.com/mail/?view=cm&fs=1&to=press@my-fantasy.life"
          )
        }
      >
        Contact: press@my-fantasy.life
      </span>
      <div
        className="pressroom-footer__contact-mobile"
        onClick={() =>
          openInNewTab(
            "https://mail.google.com/mail/?view=cm&fs=1&to=press@my-fantasy.life"
          )
        }
      >
        <span className="pressroom-footer__contact-mobile__content">
          Contact:
        </span>
        <br />
        <span className="pressroom-footer__contact-mobile__content">
          press@my-fantasy.life
        </span>
      </div>
      <span className="pressroom-footer__label w-300">
        Here you’ll find our logos, visuals, and other assets.
      </span>
      <button
        className="pressroom-footer__button"
        onClick={() => {
          ampEvent("presskit_click");
          openInNewTab(
            "https://drive.google.com/drive/folders/1RLi85teCPXVLTGu9WneshRS51CQtyS7I"
          );
        }}
      >
        Press Kit
      </button>
      <div className="pressroom-footer__emails">
        <img
          src="/img/pressroom/footer/three-diamonds.png"
          alt="Three diamonds."
          className="pressroom-footer__emails__image"
        />
        <span
          className="pressroom-footer__emails__info"
          onClick={() =>
            openInNewTab(
              "https://mail.google.com/mail/?view=cm&fs=1&to=support@my-fantasy.life"
            )
          }
        >
          Support: support@my-fantasy.life
        </span>
      </div>
      <div
        className="pressroom-footer__emails-mobile"
        onClick={() =>
          openInNewTab(
            "https://mail.google.com/mail/?view=cm&fs=1&to=my-fantasy@my-fantasy.life"
          )
        }
      >
        <span
          className="pressroom-footer__emails-mobile__info"
          onClick={() =>
            openInNewTab(
              "https://mail.google.com/mail/?view=cm&fs=1&to=support@my-fantasy.life"
            )
          }
        >
          Support:
          <br />
          support@my-fantasy.life
        </span>
      </div>
      <div className="pressroom-footer__border bottom"></div>
      <div className="pressroom-footer__info">
        <span className="pressroom-footer__info__rights">
          All Rights Reserved © 2023 MYFANTASY
        </span>
        <div className="pressroom-footer__info__wrapper">
          <div className="pressroom-footer__info__wrapper__rules">
            <span
              onClick={() => {
                ampEventWithEventProperty("button_click", {
                  parametr: "terms",
                });
                openInNewTab(
                  "https://holy-legal-docs.s3.us-east-2.amazonaws.com/myfantasy/My+Fantasy+-+General+ToS+-+November+2020.docx.pdf"
                );
              }}
            >
              terms of use
            </span>
            <span
              onClick={() => {
                ampEventWithEventProperty("button_click", {
                  parametr: "privacy",
                });
                openInNewTab(
                  "https://holy-legal-docs.s3.us-east-2.amazonaws.com/myfantasy/My+Fantasy+-+General+Privacy+Policy+-+November+2020.docx.pdf"
                );
              }}
            >
              privacy policy
            </span>
          </div>
          <div className="pressroom-footer__info__wrapper__social">
            <span
            className="mr40"
              onClick={() => {
                ampEventWithEventProperty("button_click", {
                  parametr: "Facebook",
                });
                openInNewTab(
                  "https://www.facebook.com/MyFantasy-105673391708947"
                );
              }}
            >
              Facebook
            </span>
            <span
              onClick={() => {
                ampEventWithEventProperty("button_click", {
                  parametr: "instagram",
                });
                openInNewTab(
                  "https://www.instagram.com/myfantasygame/?hl=en"
                );
              }}
            >
              Instagram
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export { PressroomFooter };
