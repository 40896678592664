import React from 'react'

import './styles.scss'

const Fact = ({ title, content }) => {
	return (
		<div className="fact">
			<h2 className="fact__title">{title}</h2>
			<span className="fact__content">{content}</span>
		</div>
	)
}

export { Fact }
