import React from "react";
import * as queryString from "query-string";

import Pressroom from "./screens/pressroom";

import { ampSetUserProperty } from "./utils/amplitude";

import "./App.scss";

const App = () => {
  const params = queryString.parse(window.location.search);

  React.useEffect(() => {
    window.scrollTo(0, 0);

    Object.keys(params).forEach((elem) => {
      ampSetUserProperty(elem, params[elem]);
    });
  }, [params]);

  return (
    <div className="app">
      <Pressroom />
    </div>
  );
};

export default App;
