import React from "react";
import { Route, Routes } from "react-router-dom";
import App from "./App";
import Policy from "./screens/policy/index";
import TermsOfUse from "./screens/termsOfUse";
import MailRedirect from "./screens/mailRedirect";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/policy" element={<Policy />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route path="/privacy" element={<Policy />} />
      <Route path="/support" element={<MailRedirect />} />
    </Routes>
  );
};
