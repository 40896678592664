import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const MailRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = "mailto:support@my-fantasy.life";
    // Redirect back to home or any other page after opening mail client
    navigate("/");
  }, [navigate]);

  return null;
};

export default MailRedirect;
