import React from "react";

import {
  Main,
  FactsContainer,
  Team,
  CoreValues,
  Gameplay,
  ReviewsContainer,
} from "../../components/pressroom";
import {
  PressroomFooter,
  PressroomHeader,
} from "../../components/pressroom/common";
import { ampEvent } from "../../utils/amplitude";

import "./styles.scss";

const Pressroom = () => {
  React.useEffect(() => {
    setTimeout(() => {
      ampEvent("page_view");
    }, 0);
  }, []);

  return (
    <div className="pressroom">
      <PressroomHeader />
      <Main />
      <FactsContainer />
      <Team />
      <CoreValues />
      <Gameplay />
      <ReviewsContainer />
      <PressroomFooter />
    </div>
  );
};

export { Pressroom };
