import React from 'react'

import './styles.scss'

const Value = ({ title, content }) => {
	return (
		<div className="value">
			<span className="value__title">{title}</span>
			<span className="value__content">{content}</span>
		</div>
	)
}

export { Value }
