import React from "react";
import { Fact } from "../common/fact";

import "./styles.scss";

const FactsContainer = () => {
  return (
    <div className="facts-container">
      <div className="facts-container__header">
        <img
          src="/img/pressroom/fact/ticket.png"
          alt="Ticket."
          className="facts-container__header__image"
        />
        <h2 className="facts-container__header__title">Fast Facts</h2>
      </div>
      <div className="facts-container__facts">
        <Fact title="80%" content="Diverse female-forward team" />
        <div className="facts-container__facts__border"></div>
        <Fact
          title="1.5M"
          content="Players are already exploring their fantasies & opening up to new emotions"
        />
        <div className="facts-container__facts__border"></div>
        <Fact
          title="60+"
          content="Original stories will take you on a journey beyond your imagination with new content released every week"
        />
        <div className="facts-container__facts__border"></div>
        <Fact
          title="300K+"
          content={`Players join "My Fantasy" monthly ready to discover and embrace their desires`}
        />
      </div>
    </div>
  );
};

export { FactsContainer };
