import React from 'react'

import './styles.scss'

const Review = ({ name, content }) => {
	return (
		<div className="review">
			<img
				src={`/img/pressroom/reviews/${name}.png`}
				alt={name}
				className="review__image"
			/>
			<div className="review__info">
				<span className="review__info__content">{content}</span>
				<span className="review__info__name">{name}</span>
			</div>
		</div>
	)
}

export { Review }
