import React from 'react';

const TermsOfUse = () => {
    return (
        <iframe
            title='terms of use'
            style={{ width: '130%', height: '100%', margin: '40px -70px 0px' }}
            src='pdf/terms.html'
            frameBorder="0"
        />
    );
};

export default TermsOfUse;