import React from 'react'

import './styles.scss'

const Gameplay = () => {
	return (
		<div className="gameplay">
			<h2 className="gameplay__title">
				Choose the way you look and your style of playing
			</h2>
			<img
				src="/img/pressroom/gameplay/gameplay.png"
				alt="Game play of Fantasy."
				className="gameplay__image"
			/>
			<img
				src="/img/pressroom/gameplay/character.png"
				alt="Game play of Fantasy."
				className="gameplay__image-character"
			/>
			<img
				src="/img/pressroom/gameplay/gameplay-mobile.png"
				alt="Game play of Fantasy."
				className="gameplay__image-mobile"
			/>
		</div>
	)
}

export { Gameplay }
