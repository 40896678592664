import React from "react";

import "./styles.scss";

const PressroomHeader = () => {
  return (
    <header className="pressroom__header">
      <div className="pressroom__header__wrapper">
        <img
          src="/img/pressroom/logo.png"
          alt="My Fantasy logo."
          className="pressroom__header__image"
        />
      </div>
      <div className="pressroom__header__wrapper">
        <a
          href="https://www.facebook.com/MyFantasy-105673391708947"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="img/pressroom/facebook.png"
            alt="facebook"
            className="pressroom__header__icon"
          />
        </a>
        <a
          href="https://www.instagram.com/myfantasygame/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="img/pressroom/instagram.png"
            alt="instagram"
            className="pressroom__header__icon"
          />
        </a>
      </div>
    </header>
  );
};

export { PressroomHeader };
