import React from 'react'

import './styles.scss'

const Member = ({ name, position, description }) => {
	return (
		<div className="member">
			<img
				src={`/img/pressroom/team/${name}.png`}
				alt={name}
				className="member__image"
			/>
			<div className="member__info">
				<span className="member__info__name">{name}</span>
				<span className="member__info__position">{position}</span>
				<span className="member__info__description">{description}</span>
			</div>
		</div>
	)
}

export { Member }
