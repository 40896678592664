import React from 'react'
import { Value } from '../common'

import './styles.scss'

const CoreValues = () => {
	return (
		<div className="core-values">
			<div className="core-values__border upper"></div>
			<div className="core-values__header">
				<img
					className="core-values__header__image"
					src="/img/pressroom/values/wallet.png"
					alt="Red wallet."
				/>
				<h2 className="core-values__header__title">
					&ldquo;My Fantasy&rdquo; core values
				</h2>
			</div>
			<div className="core-values__wrapper">
				<Value
					title="Self-fulfilled heroes"
					content="Characters in our stories are adult, independent, and morally strong people, who seek to be in healthy and respectful relationships."
				/>
				<Value
					title="Equal relationships"
					content="Relationships in our stories are a union of characters, who work through trials and tribulations and reach their life potential together."
				/>
				<Value
					title="Wholesome fantasies"
					content="We cover wildest and boldest fantasies, and pack them into plots to everyone's taste. But we never cross the lines of safety."
				/>
				<Value
					title="Diversity"
					content="There is no place for racism, homophobia, transphobia, and other negative movements in our stories."
				/>
				<Value
					title="Safety first"
					content="It's just a game, but we care about it being a safe place. A healthy exchange based on protection and consent of all participants comes first."
				/>
				<Value
					title="Self-discovery"
					content="“My Fantasy” offers the opportunity to explore sides of ourselves we wouldn't necessarily explore in normal life. "
				/>
			</div>
			<div className="core-values__border bottom"></div>
		</div>
	)
}

export { CoreValues }
