import React from 'react'

import { Member } from '../common'

import './styles.scss'

const Team = () => {
	return (
		<div className="team">
			<div className="team__header">
				<img
					src="img/pressroom/team/diamond.png"
					alt="Diamond."
					className="team__header__image"
				/>
				<h2 className="team__header__title">Leadership</h2>
			</div>
			<div className="team__cards">
				<Member name="Bogdan Nesvit" position="Chief Executive Officer" description="That’s quite a success that we don't feel ashamed anymore to say the word ‘sex’ out loud. But there’s still a lot of stigma around sex-related topics, including sexual fantasies. "/>
				<Member name="Maja Skvorc" position="Head of Narrative Design" description="You have the right to desire and get what you want, even if it’s achieved in a non-traditional way.
I think of our app as a trial version for fantasy. "/>
			</div>
		</div>
	)
}

export { Team }
